<template>
  <div class="changePassword-wrapper">
    <v-row class="hidden-md-and-up" style="height:75px">
      <v-col cols="12" class="password-mobile-header text-center">
        {{ $t(`label.changePassword`) }}
      </v-col>
    </v-row>
    <v-card style="height: 100%" class="" :class="$vuetify.breakpoint.mdAndUp ? 'desktop_password_card ml-4' : 'desktop_card'">
      <v-card-title class="hidden-sm-and-down mx-6">
        <label class="--v-primary-base text-left password-desktop-txt text-capitalize">{{ $t(`label.changePassword`) }}</label>
      </v-card-title>
      <v-card class="profile-detail-card profile-overflow" elevation="0">
        <v-form ref="changePasswordForm">
          <v-row no-gutters align="end" :class="$vuetify.breakpoint.mdAndUp ? 'pt-4' :'px-8 pt-4'">
            <v-col lg=4 md=6>
              <app-form-field v-model.trim="changePasswordObj.oldPassword" :enabledLabel=true :label="$t(`field.currentPassword`)" :placeholder="$t(`fieldHint.fillUpPwdHere`)" :rules="validator.passwordRules()" type="password"></app-form-field>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" :class="$vuetify.breakpoint.mdAndUp ? '' :'px-8'">
            <v-col lg=4 md=6>
              <app-form-field v-model.trim="changePasswordObj.newPassword" :enabledLabel=true :tooltip="$t(`fieldHint.password`)" :label="$t(`field.newPassword`)" :placeholder="$t(`fieldHint.fillUpPwdHere`)" :rules="validator.passwordRules(memberInfo.memberCode)" type="password"></app-form-field>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" :class="$vuetify.breakpoint.mdAndUp ? '' :'px-8'">
            <v-col lg=4 md=6 class="mb-4">
              <app-form-field v-model.trim="changePasswordObj.confirmNewPassword" :enabledLabel=true :label="$t(`field.confirmNewPassword`)" :placeholder="$t(`fieldHint.fillUpPwdHere`)" :rules="validator.confirmPasswordRules(changePasswordObj.newPassword, changePasswordObj.confirmNewPassword)"
                              type="password"></app-form-field>
<!--              <app-button class="dialog-button submit-btn" :action="this.changePassword" :title="$t(`button.submit`)"></app-button>-->
              <v-btn
                  class="dialog-button"
                  @click="this.changePassword"
                  :class="$vuetify.breakpoint.mdAndUp ? 'pass-btn-desktop pass-width' : 'submit-btn pass-width-mobile'"
              >
                {{ $t(`button.submit`) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-card>
  </div>

</template>

<script>
  import stingFormat from 'string-format'
  import {
    locale,
    formValidator,
    errorCodeHelper
  } from '@/util'
  import {
    MEMBER_CHANGE_PASSWORD,
    MEMBER_RESET_CHANGE_PASSWORD_STORE
  } from '@/store/member.module'
  export default {
    name: 'appChangePassword',
    mounted() {
      this.$refs.changePasswordForm.reset()
    },
    data: () => ({
      validator: formValidator,
      stingFormat: stingFormat,
      retainSignIn: false,
      changePasswordObj: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }
    }),
    computed: {
      memberInfo() {
        return this.$store.state.member.info
      },
      changePasswordResponseComplete() {
        return this.$store.state.member.changePasswordResponse.complete
      }
    },
    watch: {
      changePasswordResponseComplete() {
        let response = this.$store.state.member.changePasswordResponse
        if (response.complete) {
          this.changePasswordResponseCompleteDialog(response)
          this.$store.dispatch(`${MEMBER_RESET_CHANGE_PASSWORD_STORE}`)
        }
      }
    },
    methods: {
      changePassword() {
        if (this.$refs.changePasswordForm.validate()) {
          let changePasswordObj = this.changePasswordObj
          this.$store.dispatch(`${MEMBER_CHANGE_PASSWORD}`, {
            changePasswordObj
          })
        }
      },
      changePasswordResponseCompleteDialog(response) {
        if (response.code != 0 && !response.success) {
            this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
        } else {
            this.$parent.openAlert(true, locale.getMessage(`message.memberPasswordUpdated`))
        }
      }
    }
  }
</script>

<style lang="scss">
.changePassword-wrapper{
  height: 100%;
}

.desktop_password_card {
  border-radius: 12px !important;
  height: 100% !important;
  min-height: 700px;
  font-family: "Roboto", sans-serif;

  .password-desktop-txt {
    font-weight: bold;
  }
}

.submit-btn{
  background-color: var(--v-newMobileSecondary-base) !important;
  color:#FFFFFF !important;
}

.pass-btn-desktop {
  background-color: #0094FF !important;
  height: 40px !important;
  font-family: "Roboto", sans-serif !important;
  border-radius: 12px !important;
  font-size: 14px;
  color: white !important;
}

.pass-width {
  width: 75%;
}
.pass-width-mobile {
  width: 100%;
}

.password-mobile-header{
  background-color: #000000;
  color: var(--v-newMobilePrimary-base);
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 959px){

  .submit-btn.buttonPrimary{
    background-color: var(--v-newMobileSecondary-base) !important;
    color:#FFFFFF;
  }
}

@media (max-width: 959px) and (min-width: 849px) {
  .desktop_password_card {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 0px !important;
  }
  .profile-overflow {
    overflow-x: hidden !important;
  }
}
</style>
